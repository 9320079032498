import axios from 'axios';
import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
// import { getDateWithFormat } from '../../helpers/Utils';
import { servicePath } from '../../constants/defaultValues';
import { getCurrentUser } from '../../helpers/Utils';

import {
  // SURVEY_LIST_ADD_ITEM,
  ARREMATANTE_PRESENCIAL_LIST_GET_LIST,
  // ARREMATANTE_PRESENCIAL_LIST_ADD_ITEM,
} from '../actions';

import {
  // addSurveyItemSuccess,
  // addSurveyItemError,
  getArrematanteListSuccess,
  getArrematanteListError,
  // addArrematanteItemSuccess,
} from './actions';

// import surveyListData from '../../data/survey.list.json';
const apiUrl = servicePath;

const getArrematantePresencialListRequest = async (id) => {
  const currentUser = getCurrentUser();

  // eslint-disable-next-line no-return-await
  return axios
    .get(`${apiUrl}/usuarios/listuserleiloespresenciais?id=${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentUser.id}`,
      },
    })
    .then((response) => response)
    .catch((error) => error);
};

function* getArrematantePresencialItems({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(getArrematantePresencialListRequest, id);
    yield put(getArrematanteListSuccess(response));
  } catch (error) {
    yield put(getArrematanteListError(error));
  }
}
/*
const addSurveyItemRequest = async (item) => {
  const items = surveyListData.data;
  // eslint-disable-next-line no-param-reassign
  item.id = items.length + 1;
  // eslint-disable-next-line no-param-reassign
  item.createDate = getDateWithFormat();
  items.splice(0, 0, item);
  // eslint-disable-next-line no-return-await,no-unused-vars
  return await new Promise((success) => {
    setTimeout(() => {
      success(items);
    }, 1000);
  })
    .then((response) => response)
    .catch((error) => error);
};

function* addSurveyItem({ payload }) {
  try {
    const response = yield call(addSurveyItemRequest, payload);
    yield put(addSurveyItemSuccess(response));
  } catch (error) {
    yield put(addSurveyItemError(error));
  }
}
*/

// eslint-disable-next-line import/prefer-default-export
export function* watchGetList() {
  yield takeEvery(
    ARREMATANTE_PRESENCIAL_LIST_GET_LIST,
    getArrematantePresencialItems
  );
}

/*
export function* wathcAddItem() {
  yield takeEvery(SURVEY_LIST_ADD_ITEM, addSurveyItem);
}

*/
export default function* rootSaga() {
  yield all([fork(watchGetList)]);
}
