// eslint-disable-next-line import/no-cycle
import {
  ARREMATANTE_PRESENCIAL_LIST_ADD_ITEM,
  ARREMATANTE_PRESENCIAL_LIST_ADD_ITEM_SUCCESS,
  ARREMATANTE_PRESENCIAL_LIST_GET_LIST,
  ARREMATANTE_PRESENCIAL_LIST_GET_LIST_SUCCESS,
  ARREMATANTE_PRESENCIAL_LIST_GET_LIST_ERROR,
} from '../actions';

export const getArrematanteList = (id) => ({
  type: ARREMATANTE_PRESENCIAL_LIST_GET_LIST,
  payload: id,
});

export const getArrematanteListSuccess = (items) => ({
  type: ARREMATANTE_PRESENCIAL_LIST_GET_LIST_SUCCESS,
  payload: items,
});

export const getArrematanteListError = (error) => ({
  type: ARREMATANTE_PRESENCIAL_LIST_GET_LIST_ERROR,
  payload: error,
});

export const addArrematante = (item) => ({
  type: ARREMATANTE_PRESENCIAL_LIST_ADD_ITEM,
  payload: item,
});

export const addArrematanteItemSuccess = (items) => ({
  type: ARREMATANTE_PRESENCIAL_LIST_ADD_ITEM_SUCCESS,
  payload: items,
});
