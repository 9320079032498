import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
// import { auth } from '../../helpers/Firebase';
import { NotificationManager } from '../../components/common/react-notifications';
import api from '../../helpers/authconf';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from '../actions';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from './actions';

import { adminRoot, currentUser } from '../../constants/defaultValues';
import { setCurrentUser } from '../../helpers/Utils';

export function* watchLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) =>
  // eslint-disable-next-line no-return-await
  await api
    .post('/loginadm/adm', {
      email,
      password,
    })
    .then((user) => user)
    .then((token) => token)
    .catch((error) => error);

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  let roleUser = 1;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    if (!loginUser.message) {
      // const item = { uid: loginUser.user.uid, ...currentUser };
      let avatarUrl = null;
      if (loginUser.data.userdados.avatar_url) {
        const urlImagem =
          loginUser.data.userdados.avatar === null ||
          loginUser.data.userdados.avatar === ''
            ? '/assets/img/profiles/perfilsemfoto.jpeg'
            : loginUser.data.userdados.avatar_url;
        avatarUrl = urlImagem;
      }

      if (loginUser.data.userdados.type === 4) {
        roleUser = 0;
      }

      if (loginUser.data.userdados.type === 5) {
        roleUser = 5;
      }

      const item = {
        id: loginUser.data.userdados.id,
        img:
          loginUser.data.userdados.avatar === ''
            ? '/assets/img/profiles/perfilsemfoto.jpeg'
            : avatarUrl,
        uid: loginUser.data.token,
        name: loginUser.data.userdados.name,
        namefull: loginUser.data.userdados.namecomplete,
        cidade: loginUser.data.userdados.cidade,
        estado: loginUser.data.userdados.estado,
        date: loginUser.data.userdados.data,
        avatar: loginUser.data.userdados.avatar,
        avatar_url: avatarUrl,
        role: roleUser,
        ent: loginUser.data.userdados.emt,
      };

      setCurrentUser(item);
      yield put(loginUserSuccess(item));
      history.push(adminRoot);

      if (loginUser.data.userdados.namecomplete) {
        NotificationManager.success(
          `Login Efetuado com sucesso.`,
          `Bem vindo ${loginUser.data.userdados.namecomplete}`,
          3000,
          null,
          null,
          ''
        );
      }
    } else {
      yield put(
        loginUserError(
          `${loginUser.response.data.status} - ${loginUser.response.data.message}`
        )
      );
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

export function* watchRegisterUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password) =>
  // eslint-disable-next-line no-return-await
  await api
    .post('/loginadm/adm', {
      email,
      password,
    })
    .then((user) => user)
    .then((token) => token)
    .catch((error) => error);
/*
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then((user) => user)
    .catch((error) => error);
    */

function* registerWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password
    );
    if (!registerUser.message) {
      const item = { uid: registerUser.user.uid, ...currentUser };
      setCurrentUser(item);
      yield put(registerUserSuccess(item));
      history.push(adminRoot);
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  /*
  await auth
    .signOut()
    .then((user) => user)
    .catch((error) => error);
  */
  localStorage.removeItem('federal_user');
  if (history) {
    history.push('./'); // Logout envia para página de login
  }
};

function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser();
  yield call(logoutAsync, history);
}

export function* watchForgotPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  await api
    .post('/password/forgotPasswordAdm', {
      email,
    })
    .then((user) => user)
    .catch((error) => error);

  return api;
  // eslint-disable-next-line no-return-await
  /*
  return await auth
    .sendPasswordResetEmail(email)
    .then((user) => user)
    .catch((error) => error);
  */
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  console.log(resetPasswordCode, newPassword);
  // eslint-disable-next-line no-return-await
  /*
  return await auth
    .confirmPasswordReset(resetPasswordCode, newPassword)
    .then((user) => user)
    .catch((error) => error);
    */
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}
