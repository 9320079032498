/* eslint-disable prefer-template */
import axios from 'axios';
import moment from 'moment';
// import 'moment/locale/pt-br';
import 'moment-timezone';

import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
  servicePath,
} from '../constants/defaultValues';

moment.tz.setDefault('America/Sao_Paulo');

export const mapOrder = (array, order, key) => {
  // eslint-disable-next-line func-names
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getDateWithFormatPt = (date, type) => {
  const today = new Date(date);
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!
  let hh = today.getHours();
  let min = today.getMinutes();
  let ss = today.getSeconds();
  let yyyy = today.getFullYear();

  if (type === 'UTC') {
    yyyy = today.getUTCFullYear();
    dd = today.getUTCDate();
    mm = today.getUTCMonth() + 1;
    hh = today.getUTCHours();
    min = today.getUTCMinutes();
    ss = today.getUTCSeconds();

    // formatDate(getUTCDate(), 'DD MMM, YYYY HH:mm:ss');
  }

  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  if (hh < 10) {
    hh = `0${hh}`;
  }
  if (min < 10) {
    min = `0${min}`;
  }
  if (ss < 10) {
    ss = `0${ss}`;
  }
  return `${dd}/${mm}/${yyyy} ${hh}:${min}:${ss}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setDirection -> error', error);
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentColor -> error', error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentColor -> error', error);
  }
};

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentRadius -> error',
      error
    );
    currentRadius = 'rounded';
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentRadius -> error',
      error
    );
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem('currentLanguage') &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage')
      ).length > 0
        ? localStorage.getItem('currentLanguage')
        : defaultLocale;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentLanguage -> error',
      error
    );
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
      error
    );
  }
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem('federal_user') != null
        ? JSON.parse(localStorage.getItem('federal_user'))
        : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null;
  }
  return user;
};

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('federal_user', JSON.stringify(user));
    } else {
      localStorage.removeItem('federal_user');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

// Verifica se o token é válido no front-end apenas -
export const AuthVerify = () => {
  const currentUser = getCurrentUser();
  let token = null;
  if (currentUser) {
    token = currentUser.uid;
  }

  const decodedJwt = parseJwt(token);

  if (decodedJwt === null) {
    return false;
  }

  if (decodedJwt.exp * 1000 < Date.now()) {
    return false;
  }

  return true;
};

// Verifica se o token é válido no backend
export const checkAuth = async () => {
  const currentUser = getCurrentUser();
  let token = null;
  if (currentUser) {
    token = currentUser.uid;
  }
  const apiUrLogin = `${servicePath}/login/tocheckLogged`;
  const checklogado = await axios
    .get(`${apiUrLogin}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      // console.log('este', response.data);
      if (response.data === 'erro') {
        return false;
      }
      return true;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
  // console.log('teste', checklogado);
  return checklogado;
};

export const updateUser = async (data) => {
  const currentUser = getCurrentUser();
  let token = null;
  if (currentUser) {
    token = currentUser.uid;
  } else {
    return false;
  }

  const item = {
    id: data.id,
    img: data.avatar_url,
    uid: token,
    name: data.name,
    namefull: data.namecomplete,
    cidade: data.cidade,
    estado: data.estado,
    date: data.data,
    avatar: data.avatar,
    avatar_url: data.avatar_url,
    role: 1,
  };

  setCurrentUser(item);

  return true;
};

// FUNÇÃO PARA TRATAR DATAS PARA COMPONENTE DE DATAS - RETORNA DATA NO FORMADO parseISO - UTF
export const trataData = (data) => {
  if (data) {
    // const dtinicial = new Date(data);
    /*
    const dd = dtinicial.getDate();
    const mm = dtinicial.getMonth();
    // const hh = dtinicial.getHours();
    const hh = dtinicial.getUTCHours();
    const min = dtinicial.getMinutes();
    const ss = dtinicial.getSeconds();
    const yyyy = dtinicial.getFullYear();
    */
    // const { yyyy, mm, dd, hh, min, ss } = data;

    const dataFormatada = moment(data).format('YYYY-MM-DD HH:mm:ss');
    const dataFormatadaFim = new Date(dataFormatada);

    // console.log(data);
    /*
    const timezone = 'America/Sao_Paulo';
    const momentClass = moment(data).tz(timezone);
    console.log(momentClass);
    */
    // const dtinicialFormat = new Date(Date.UTC(yyyy, mm, dd, hh, min, ss));
    // dtinicialFormat.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' });
    // const dataLocal = new Date(yyyy, mm, dd, hh, min, ss);
    return dataFormatadaFim;
  }
  return '';
};
