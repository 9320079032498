import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // 'http://localhost:3333',
});

export default api;

/*
proxy: {
  protocol: 'https',
  host: '127.0.0.1',
  port: 9000,
  auth: {
    username: 'mikeymike',
    password: 'rapunz3l'
  }
},
*/
